<template>
  <div>
    <h3 class="mb-3">
      <v-icon class="pr-1">mdi-truck-outline</v-icon> Informações
    </h3>

    <v-row>
      <v-col>
        <small>Descrição:</small>
        <br />
        <b>
          {{ stockTransfer.description }}
        </b>
        <DeliveryStatusChip class="ml-5" small :status="stockTransfer.status" />

        <template v-if="stockTransfer.status == 'canceled'">
          <v-row>
            <v-col>
              Cancelado por: {{ stockTransfer.canceled_by.name }} em
              {{ $format.dateTimeBr(stockTransfer.canceled_at) }}
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-space-between justify-md-end">
        <div class="mr-5">
          <v-btn class="mr-2" color="error" outlined v-if="isPending" @click="allowedToCancel = true">
            Cancelar
          </v-btn>
          <v-btn v-if="isPending" color="primary" outlined @click="updateToConfirmed()">
            Confirmar
          </v-btn>

          <v-btn v-if="canReceive" color="primary" outlined @click="updateToReceived()">
            Marcar como Recebido
          </v-btn>
          <v-btn v-if="canEdit" @click="handleEditTransferReceipt(stockTransfer.id)" color="primary">
            <app-icon left>edit</app-icon>
            Editar
          </v-btn>
        </div>

        <v-btn v-if="canPrint" @click="printTransfer(stockTransfer.id)" color="primary">
          <app-icon left>print</app-icon>
          Imprimir
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pr-md-0">
        <v-card outlined>
          <v-card-title>
            <span class="headline">
              {{ stockTransfer.company_origin.name }}
            </span>
          </v-card-title>
          <v-card-text>
            Emitido: {{ stockTransfer.created_by.name }} em
            {{ $format.dateTimeBr(stockTransfer.issued_at) }}

            <div v-if="stockTransfer.confirmed_by">
              Confirmado: {{ stockTransfer.confirmed_by.name }} em
              {{ $format.dateTimeBr(stockTransfer.confirmed_at) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <div class="d-flex justify-center align-center col-md-1 col-12" style="padding: 0px">
        <v-icon color="primary" class="d-none d-md-flex" size="50px">
          mdi-arrow-right-thick
        </v-icon>
        <v-icon color="primary" class="d-md-none d-flex" size="50px">
          mdi-arrow-down-thick
        </v-icon>
      </div>

      <v-col class="pl-md-0">
        <v-card outlined>
          <v-card-title>
            <span class="headline">
              {{ stockTransfer.company_destination.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <span v-if="stockTransfer.received_by">
              Recebido: {{ stockTransfer.received_by.name }} em
              {{ $format.dateTimeBr(stockTransfer.received_at) }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h3 class="mt-6 mb-3">
      <v-icon class="pr-1">mdi-package-variant-closed</v-icon> Produtos
    </h3>
    <template v-if="!$vuetify.breakpoint.mobile">
      <v-row>
        <v-col>
          <v-data-table dense disable-sort hide-default-footer :items="products" :headers="headers"
            :items-per-page="-1">
            <template v-slot:[`item.quantity`]="{ item }">
              {{ $format.decimal(item.quantity) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-if="$vuetify.breakpoint.mobile">
      <v-list dense>
        <v-divider />
        <template v-for="(row, index) in products">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title> {{ row.product.name }} </v-list-item-title>
              <v-list-item-subtitle>
                Qt: {{ $format.decimal(row.quantity) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`index-${index}`" />
        </template>
      </v-list>
    </template>

    <v-row class="my-2" v-if="stockTransfer.observations">
      <v-col>
        <h5>Observação:</h5>

        <b>{{ stockTransfer.observations }}</b>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col class="text-right">
        <v-btn @click="$router.go(-1)" class="text-capitalize mr-2" text color="secondary">
          Voltar
        </v-btn>
      </v-col>
    </v-row>
    <!-- Modal (Diálogo) -->
    <v-dialog v-model="allowedToCancel" max-width="500px" persistent>
      <v-card>
        <v-card-title class="d-flex text-center pt-10">
          <v-icon color="error" left>mdi-alert-circle</v-icon>
          <h4 class="mb-0">Você tem certeza que deseja cancelar?</h4>
        </v-card-title>
        <v-card-subtitle class="text-center pb-10">
          Essa ação não pode ser desfeita.
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions style="background-color: #fff;">
          <v-btn color="error" large @click="updateToCanceled" class="mr-4">
            Confirmar
          </v-btn>
          <v-btn outlined color="primary" large @click="allowedToCancel = false">
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>
import DeliveryStatusChip from "@/components/delivery/section/DeliveryStatusChip.vue";

export default {
  components: {
    DeliveryStatusChip,
  },

  data() {
    return {
      allowedToCancel: false,
      stockTransfer: {
        company_origin: {},
        company_destination: {},
        created_by: {},
        received_by: {},
        stock_transfer_products: [],
        status: "draft",
      },
      headers: [
        {
          width: "80%",
          align: "start",
          text: "Produto",
          value: "product.name",
        },
        {
          width: "20%",
          align: "center",
          text: "Quantidade",
          value: "quantity",
        },
        { align: "end", text: "", value: "action" },
      ],
    };
  },

  created() {
    this.select();
  },

  computed: {
    products() {
      return this.stockTransfer.stock_transfer_products;
    },

    canReceive() {
      return (
        this.stockTransfer.status == "confirmed" &&
        this.$acl.can("stockTransferReceive")
      );
    },

    canCancel() {
      return (
        this.stockTransfer.status == "confirmed" &&
        this.$acl.can("stockTransferCancel")
      );
    },

    canEdit() {
      return (
        this.stockTransfer.status == "draft" &&
        this.$acl.can("stockTransferUpdate")
      );
    },

    isPending() {
      return this.stockTransfer.status == "pending";
    },

    isConfirmed() {
      return this.stockTransfer.status == "confirmed";
    },
    canPrint() {
      return (
        !this.isPending &&
        this.stockTransfer.status != "canceled" &&
        this.stockTransfer.status != "draft"
      );
    },
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .show("stock/stock-transfer", this.$route.params.id)
        .then((response) => {
          this.stockTransfer = JSON.parse(JSON.stringify(response.transfer));
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleEditTransferReceipt() {
      this.$router.push({
        name: "UpdateStockTransfer",
        params: { id: this.stockTransfer.id },
      });
    },

    async updateToConfirmed() {

      await this.updateStatus("confirmed")
        .then((response) => this.printTransfer())
      this.$loading.finish();
    },



    async updateToReceived() {
      await this.updateStatus("received");
    },

    async updateToCanceled() {
      await this.updateStatus("canceled");
      this.allowedToCancel = false
    },

    async updateStatus(status) {
      this.$loading.start();
      return new Promise((resolve, reject) => {
        this.$http
          .update("stock/stock-transfer/update", this.stockTransfer.id, {
            status: status,
          })
          .then((response) => {
            resolve(response.data)
            this.select()
            this.$loading.finish();
          }).catch(error => {

            reject(error)
            this.$loading.finish();
          });
      })

    },
    printTransfer() {
      window.open(`/transferencia/imprimir/${this.stockTransfer.id}`);
    },
  },
};
</script>

<style></style>